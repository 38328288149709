import { ref } from 'vue';

const isOpen = ref<boolean>(false);
const confirmMessage = ref<string | null>(null);
const proceedCallback = ref<null | (() => void)>(null);

export function useConfirm() {
  function confirm(callback: () => void, message: string | null = null) {
    isOpen.value = true;
    proceedCallback.value = callback;
    confirmMessage.value = message;
  }

  function proceed(): void {
    if (proceedCallback.value) {
      proceedCallback.value();
    }
    isOpen.value = false;
    confirmMessage.value = null;
  }

  function cancel(): void {
    proceedCallback.value = null;
    confirmMessage.value = null;
    isOpen.value = false;
  }

  return {
    isOpen,
    confirmMessage,
    proceed,
    cancel,
    confirm,
  };
}
