import { ref } from 'vue';

const featureWarningMessage = ref<string | null>(null);

export function useFeatureWarning() {
  function setFeatureWarningMessage(warningMessage: string | null) {
    featureWarningMessage.value = warningMessage;
  }

  return {
    setFeatureWarningMessage,
    featureWarningMessage,
  };
}
